import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import "./destinations.scss"

import Destinations from "./components/destinations-listings"






const DestinationListings = () => (
  <Layout>
    <Seo title="Royal Palm Destinations | Royal Palm Destinations Real Estate " />
    <Destinations/>
  </Layout>
)

export default DestinationListings;
